html, body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
}
